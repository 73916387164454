import image2VideoProcessing from "../../../../content/blog/2022/05/basics-of-video-processing/basics_of_video_processing_2.png";
import image3VideoProcessing from "../../../../content/blog/2022/05/basics-of-video-processing/basics_of_video_processing_3.png";
import image4VideoProcessing from "../../../../content/blog/2022/05/basics-of-video-processing/basics_of_video_processing_4.png";
import image5VideoProcessing from "../../../../content/blog/2022/05/basics-of-video-processing/basics_of_video_processing_5.png";
import * as React from 'react';
export default {
  image2VideoProcessing,
  image3VideoProcessing,
  image4VideoProcessing,
  image5VideoProcessing,
  React
};