import image1PrivateCloudfrontConfig from "../../../../content/blog/2023/01/private-cloudfront-configuration/private-cloudfront-config-1.png";
import image2PrivateCloudfrontConfig from "../../../../content/blog/2023/01/private-cloudfront-configuration/private-cloudfront-config-2.png";
import image3PrivateCloudfrontConfig from "../../../../content/blog/2023/01/private-cloudfront-configuration/private-cloudfront-config-3.png";
import image4PrivateCloudfrontConfig from "../../../../content/blog/2023/01/private-cloudfront-configuration/private-cloudfront-config-4.png";
import image5PrivateCloudfrontConfig from "../../../../content/blog/2023/01/private-cloudfront-configuration/private-cloudfront-config-5.png";
import image6PrivateCloudfrontConfig from "../../../../content/blog/2023/01/private-cloudfront-configuration/private-cloudfront-config-6.png";
import * as React from 'react';
export default {
  image1PrivateCloudfrontConfig,
  image2PrivateCloudfrontConfig,
  image3PrivateCloudfrontConfig,
  image4PrivateCloudfrontConfig,
  image5PrivateCloudfrontConfig,
  image6PrivateCloudfrontConfig,
  React
};