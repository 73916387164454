import image2 from "../../../../content/blog/2021/04/git-merge-vs-rebase/git-rebase-v-merge-2.png";
import image3 from "../../../../content/blog/2021/04/git-merge-vs-rebase/git-rebase-v-merge-3.png";
import image4 from "../../../../content/blog/2021/04/git-merge-vs-rebase/git-rebase-v-merge-4.png";
import image5 from "../../../../content/blog/2021/04/git-merge-vs-rebase/git-rebase-v-merge-5.png";
import image6 from "../../../../content/blog/2021/04/git-merge-vs-rebase/git-rebase-v-merge-6.png";
import image7 from "../../../../content/blog/2021/04/git-merge-vs-rebase/git-rebase-v-merge-7.png";
import * as React from 'react';
export default {
  image2,
  image3,
  image4,
  image5,
  image6,
  image7,
  React
};